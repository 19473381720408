import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactSection from '../components/contactSection'

const ContactPage = () => (
    <Layout>
        <SEO title="contact:title" description="contact:paragraph" />
        <ContactSection />
    </Layout>
)

export default ContactPage
